import React from 'react';
import s from './Promocode.module.css';
import copy from '../../images/copy-icon.svg';
import copyActive from '../../images/copy-active.svg';

export default function Promocode({ code, setCopied, copied }) {
  const copyTextToClipboard = async (code) => {
    try {
      await navigator.clipboard.writeText(code);
      console.log('Текст успешно скопирован в буфер обмена!');
    } catch (err) {
      console.error('Ошибка:', err);
    }
  };

  const handleCopy = async () => {
    await copyTextToClipboard(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className={s.promocodeBox}>
      <p className={s.promocodeTitle}>Ваш код</p>
      <div className={s.promocodeContainer}>
        <p className={s.promocode}>{code ? code : 'Загрузка...'}</p>
        <button className={s.copy} onClick={handleCopy}>
          <img src={copied ? copyActive : copy} alt="copy" />
        </button>
      </div>
    </div>
  );
}
