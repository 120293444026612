import React from 'react';
import s from './SmallCard.module.css';
import { Link } from 'react-router-dom';
import LinkButton from '../LinkButton/LinkButton';

export default function SmallCard({
  title,
  text,
  img,
  linkText,
  linkSrc,
  secondLinkText,
  secondLinkSrc,
  dark,
}) {
  return (
    // <div className={`${s.card} keen-slider__slide number-slide${number}`} style={{ minWidth: '70%' }}>
    <div className={s.card}>
      <h3 className={s.title}>{title}</h3>
      <p className={s.text}>{text}</p>

      <img src={img} alt="" className={s.img} />
      {linkText && (
        <LinkButton
          text={linkText}
          src={linkSrc}
          dark={dark === false ? false : true}
        />
      )}
      {secondLinkText && (
        <LinkButton text={secondLinkText} src={secondLinkSrc} dark={true} />
      )}
    </div>
  );
}
