import React, { useEffect, useState } from 'react';
import s from './Rent.module.css';
import Header from '../../components/Header/Header';
import PromoBox from '../../components/PromoBox/PromoBox';
import ErrorPopup from '../../components/ErrorPopup/ErrorPopup';
import img from '../../images/rent.png';

export default function Rent() {
  const [popup, setPopup] = useState(false);

  const closePopup = () => {
    setPopup(false);
  };

  useEffect(() => {
    localStorage.setItem('page', '/rent');
  }, []);
  return (
    <div className={s.page}>
      <Header transparent={true} />
      <div className={s.container}>
        <PromoBox
          title="Аренда за 10 руб. от 7 дней"
          img={img}
          promocode={true}
          promocodeValue="PR-1"
          buttonLink="https://www.iqos.ru/address/gde-kupit/?services=shop_official"
        />
        {popup && <ErrorPopup closePopup={closePopup} />}
      </div>
    </div>
  );
}
