import React, { useState, useEffect } from 'react';
import s from './Steam.module.css';
import Header from '../../components/Header/Header';
import ComplimentButton from '../../components/ComplimentButton/ComplimentButton';
import SmallCard from '../../components/SmallCard/SmallCard';
import img1 from '../../images/smell-3.png';
import img2 from '../../images/smell-2.png';
import img3 from '../../images/smell-1.png';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

export default function Steam() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    mode: 'free-snap',
    slides: {
      perView: 1.3,
      spacing: 10,
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    localStorage.setItem('page', '/steam');
  }, []);

  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <p className={s.pretitle}>
          Нам очень жаль, что вы столкнулись с такой проблемой.
        </p>
        <h2 className={s.title}>
          Мы подготовили несколько рекомендаций, которые могут помочь это
          исправить:
        </h2>

        <div ref={sliderRef} className="keen-slider">
          <div className="keen-slider__slide number-slide1">
            <SmallCard
              title="Важно делать плавные и неглубокие затяжки."
              text="Чтобы дать табаку время прогреться и образоваться оптимальному количеству пара."
              img={img1}
            />
          </div>
          <div className="keen-slider__slide number-slide2">
            <SmallCard
              title="Возможно, выбранный вкус не оправдал ваших ожиданий."
              text="Подобрать подходящий вкус стиков не сложно с нашим Гидом по вкусам"
              img={img2}
              linkText="Подобрать свой вкус"
              linkSrc="https://cloud.iqos.ru/flavor-advisor/"
            />
          </div>
          <div className="keen-slider__slide number-slide3">
            <SmallCard
              title="Возможно, устройство давно не проходило чистку. "
              text="В фирменных магазинах Q STORE и магазинах-партнерах Q PARTNER есть сервис Профессиональная чистка, который поможет поддерживать ваше устройство в порядке."
              img={img3}
              linkText="Найти магазин"
              linkSrc="https://www.iqos.ru/address/gde-kupit/?services=shop_official"
            />
          </div>
        </div>
        {loaded && instanceRef.current && (
          <div className={s.dots}>
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={`${s.dot} ${currentSlide === idx ? s.active : ''}`}
                ></button>
              );
            })}
          </div>
        )}

        <ComplimentButton />
        <p className={s.footnote}>Партнер</p>
      </div>
    </div>
  );
}
