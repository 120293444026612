import React, { useEffect } from 'react';
import s from './Stickers.module.css';
import Header from '../../components/Header/Header';
import PromoBox from '../../components/PromoBox/PromoBox';
import img from '../../images/stickers.png';

export default function Stickers() {
  useEffect(() => {
    localStorage.setItem('page', '/stickers');
  }, []);

  return (
    <div className={s.page}>
      <Header transparent={true} />
      <div className={s.container}>
        <PromoBox
          title="Стикерпак в Telegram"
          promocode={false}
          img={img}
          link="https://t.me/addstickers/iqos_summer"
        />
      </div>
    </div>
  );
}
