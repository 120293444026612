import React, { useEffect } from 'react';
import s from './BadQuality.module.css';
import Header from '../../components/Header/Header';
import LinkButton from '../../components/LinkButton/LinkButton';
import ComplimentButton from '../../components/ComplimentButton/ComplimentButton';
// import img from '../../images/bad-quality.png';

export default function BadQuality() {
  useEffect(() => {
    localStorage.setItem('page', '/bad-quality');
  }, []);
  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <h2 className={s.title}>Спасибо, что поделились своим опытом!</h2>
        <div className={s.content}>
          <p className={s.text}>
            Мы постараемся найти решение в вашей ситуации, пожалуйста, опишите
            вашу ситуацию
          </p>

          <LinkButton
            text="Чат Службы Заботы"
            src="https://iqos.net.ru/second-chance"
            dark={true}
          />
        </div>
        <ComplimentButton />
      </div>
    </div>
  );
}
