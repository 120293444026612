import React from 'react';
import s from './AnswersList.module.css';
import AnswerButton from '../AnswerButton/AnswerButton';

export default function AnswersList({ answers }) {
  return (
    <ul className={s.list}>
      {answers?.map((item, index) => {
        return (
          <li className={s.item} key={index}>
            <AnswerButton text={item.text} link={item.link} />
          </li>
        );
      })}
    </ul>
  );
}
