import React from 'react';
import s from './LinkButton.module.css';
import arrow from '../../images/arrow.svg';
import lightarrow from '../../images/light-arrow.svg';
import { Link } from 'react-router-dom';
import { endData } from '../../utils/api';
import axios from 'axios';

export default function LinkButton({ text, src, dark }) {
  return (
    <Link
      to={src}
      className={`${s.link} ${dark ? s.dark : ''}`}
      onClick={async () => {
        await endData();

        if (window.location.pathname === '/bad-quality') {
          // const tg = window.Telegram.WebApp;
          // tg.close();

          // window.location.href = 'https://iqos.net.ru/second-chance';
          setTimeout(() => {
            const tg = window.Telegram.WebApp;
            tg.close();
          }, 1500);
        }
      }}
    >
      {text}
      <img src={dark ? lightarrow : arrow} alt="arrow" />
    </Link>
  );
}
