import React, { useEffect } from 'react';
import s from './Why.module.css';
import Header from '../../components/Header/Header';
import AnswersList from '../../components/AnswerList/AnswersList';
import ellipse from '../../images/ellipse.svg';

export default function Why() {
  const answers = [
    {
      text: 'Не нравится вкус табачных стиков (иногда хочется другого вкуса)',
      link: '/smell',
    },
    { text: 'Не нравится запах при использовании', link: '/smell' },
    { text: 'Табачные стики некачественные', link: '/bad-quality' },
    { text: 'Табачные стики дорогие', link: '/expensive' },
    {
      text: 'Недостаточно пара, не насыщаюсь, используя IQOS или lil SOLID',
      link: '/steam',
    },
    {
      text: 'Короткий сеанс, устройство IQOS или lil SOLID плохо работает, сломалось или потерялось',
      link: '/device-problems',
    },
    {
      text: 'Бывают ситуации, когда я предпочту использовать другую табачную продукцию вместо IQOS / lil SOLID',
      link: '/other-devices',
    },
    {
      text: 'Не вижу преимуществ от использования IQOS или lil SOLID',
      link: '/other-devices',
    },
  ];

  useEffect(() => {
    localStorage.setItem('page', '/why');
  }, []);

  return (
    <div className={s.page}>
      <img src={ellipse} alt="" className={s.bg} />
      <Header />
      <div className={s.content}>
        <h2 className={s.title}>
          Почему вы не используете IQOS или lil SOLID, или совмещаете их с
          курением сигарет?
        </h2>
        <AnswersList answers={answers} />
      </div>
    </div>
  );
}
