import React from 'react';
import s from './Header.module.css';
import eighteenPlus from '../../images/18+.svg';

export default function Header({ transparent }) {
  return (
    <div className={s.header}>
      <img src={eighteenPlus} alt="18+" />
      <p className={`${s.text} ${transparent ? s.transparent : ''}`}>
        ВАЖНО: Не исключает риски. Аэрозоль содержит никотин, вызывающий
        зависимость
      </p>
    </div>
  );
}
