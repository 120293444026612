import { date } from './constants';
import axios from 'axios';

if (typeof window !== 'undefined') {
  const tg = window.Telegram.WebApp;
  tg.expand();
}

function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
}

const devHost = 'https://iqosmeetingbot-test.cleverbots.ru/';
const prodHost = 'https://iqosmeetingbot.cleverbots.ru/';

// get data from url
let USER_ID = getUrlParameter('user_id') || '0';
let NAMESPACE = getUrlParameter('namespace') || 'dev';

let SPICE_ID = getUrlParameter('spice_id') || '0';

// get url prod or dev based on namespace
const URL_API = NAMESPACE === 'prod' ? prodHost : devHost;

// send data to our django service
async function sendEventAnalitic(nameEvent) {
  const jsonSend = {
    user_id: USER_ID,
    event: nameEvent,
    spice_id: SPICE_ID,
  };
  console.log(jsonSend);

  try {
    const response = await fetch(URL_API + 'api/actions/q_club/webbot', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify(jsonSend),
    });

    if (!response.ok) {
      // get error text

      const errorText = await response.text();

      var bot_creds = {
        prod: '-1001473099971', // owner Hassan
        dev: '-1001980910693', // owner Hassan
      };

      // send it to telegram error group
      var bot_token = '1812237208:AAGrOTTyRXJkkZheZJQ7Hd5pLt4_gPY40YA'; // owner Hassan
      var text =
        'From webbot: ' +
        errorText +
        ' \n\nUSER_ID: ' +
        USER_ID +
        ' \n\nSPICE_ID: ' +
        SPICE_ID;
      var url =
        'https://api.telegram.org/bot' +
        bot_token +
        '/sendMessage?chat_id=' +
        bot_creds[NAMESPACE] +
        '&parse_mode=HTML&text=' +
        text;
      await fetch(url, {
        method: 'GET',
      });

      console.error('Ошибка при отправке запроса:', errorText);
      return;
    }
  } catch (error) {
    var bot_creds = {
      prod: '-1001473099971', // owner Hassan
      dev: '-1001980910693', // owner Hassan
    };

    // send it to telegram error group
    var bot_token = '1812237208:AAGrOTTyRXJkkZheZJQ7Hd5pLt4_gPY40YA'; // owner Hassan
    var text =
      'From webbot: ' +
      error +
      ' \n\nUSER_ID: ' +
      USER_ID +
      ' \n\nSPICE_ID: ' +
      SPICE_ID;
    var url =
      'https://api.telegram.org/bot' +
      bot_token +
      '/sendMessage?chat_id=' +
      bot_creds[NAMESPACE] +
      '&parse_mode=HTML&text=' +
      text;
    await fetch(url, {
      method: 'GET',
    });

    console.error('Ошибка при отправке запроса:', error);
  }
}

const devToken = 'vqZLUQDwpk7JGEuXhFKrCV5HmPf8RTyx';
const prodToken = 'b2HXnVkFsMdGR9KW4Cz8LgDQecu5UNBa';
const TOKEN = NAMESPACE === 'prod' ? prodToken : devToken;

const startData = (text) => {
  return axios
    .post(
      `${URL_API}api/actions/q_club/survey`,
      {
        spice_id: SPICE_ID,
        status: 'Опрос начат',
        survey: 'Second_Chance',
        started_at: date,
        question_1: text,
      },
      {
        headers: { Authorization: `Token ${TOKEN}` },
      }
    )
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const sendData = (text) => {
  return axios
    .post(
      `${URL_API}api/actions/q_club/survey`,
      {
        spice_id: SPICE_ID,
        status: 'Опрос прерван',
        survey: 'Second_Chance',
        last_answer_time: date,
        question_2: text,
      },
      {
        headers: { Authorization: `Token ${TOKEN}` },
      }
    )
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const endData = () => {
  return axios
    .post(
      `${URL_API}api/actions/q_club/survey`,
      {
        spice_id: SPICE_ID,
        status: 'Опрос завершен',
        survey: 'Second_Chance',
        last_answer_time: date,
      },
      {
        headers: { Authorization: `Token ${TOKEN}` },
      }
    )
    .catch(function (error) {
      console.log(error);
    });
};

export {
  sendEventAnalitic,
  getUrlParameter,
  sendData,
  startData,
  endData,
  NAMESPACE,
  URL_API,
};
