import React, { useState } from 'react';
import s from './PromoBox.module.css';
import check from '../../images/check.svg';
import Promocode from '../Promocode/Promocode';
import { Link } from 'react-router-dom';
import LinkButton from '../LinkButton/LinkButton';

export default function PromoBoxCleaning({
  title,
  img,
  promocode,
  link,
  buttonLink,
  promocodeValue,
}) {
  const [copied, setCopied] = useState(false);

  return (
    <div className={s.promoBox}>
      <Link className={s.box} to={link}>
        <p className={s.boxText}>Ваш выбранный комплимент от бренда</p>
        <h3 className={s.boxTitle}>{title}</h3>
        <img src={img} alt="background" className={s.img} />
      </Link>
      {promocode && (
        <div className={s.container}>
          <Promocode
            code={promocodeValue}
            copied={copied}
            setCopied={setCopied}
          />

          {window.location.pathname === '/cleaning' ? (
            <p className={s.text}>
              Специальным предложением можно воспользоваться один раз в
              фирменных магазинах Q{'\u00A0'}STORE, показав код эксперту.
              Предложение будет доступно в разделе «Мои промокоды» в чат-боте
              или в личном кабинете на сайте{' '}
              <Link className={s.link} to="https://www.iqos.ru/">
                iqos.ru
              </Link>
            </p>
          ) : (
            <p className={s.text}>
              Специальным предложением можно воспользоваться один раз в
              фирменных магазинах Q{'\u00A0'}STORE, показав код эксперту.
            </p>
          )}
          <LinkButton
            text="Найти ближайший магазин"
            dark={true}
            src={buttonLink}
          />
        </div>
      )}
      <div
        className={copied ? `${s.copyConfirmed} ${s.active}` : s.copyConfirmed}
      >
        <img src={check} alt="" />
        <p>Код скопирован</p>
      </div>
    </div>
  );
}
