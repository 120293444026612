import React, { useEffect, useState } from 'react';
import s from './Cleaning.module.css';
import Header from '../../components/Header/Header';
import PromoBox from '../../components/PromoBox/PromoBox';
import ErrorPopup from '../../components/ErrorPopup/ErrorPopup';
import img from '../../images/cleaning.png';
import axios from 'axios';
import { URL_API } from '../../utils/api';

export default function Cleaning({ spiceId, promocodeHash }) {
  const [promocodeValue, setPromocodeValue] = useState();
  const [popup, setPopup] = useState(false);

  const closePopup = () => {
    setPopup(false);
  };
  const openPopup = () => {
    setPopup(true);
  };

  useEffect(() => {
    localStorage.setItem('page', '/cleaning');

    axios
      .get(
        `${URL_API}api/web_bot/promocode?spice_id=${spiceId}&hash=${promocodeHash}`
      )
      .then((response) => {
        setPromocodeValue(response.data.data.Code);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setPromocodeValue(err.response.status);
          axios
            .post(`${URL_API}api/web_bot/promocode`, {
              spice_id: spiceId,
              hash: promocodeHash,
            })
            .then((response) => {
              setPromocodeValue(response.data.data.Code);
            })
            .catch((error) => {
              openPopup();
              console.log(error);
            });
        } else {
          openPopup();
        }
      });
  }, []);

  return (
    <div className={s.page}>
      <Header transparent={true} />
      <div className={s.container}>
        <PromoBox
          title="Бесплатная профессиональная чистка"
          img={img}
          promocode={true}
          buttonLink="https://www.iqos.ru/address/gde-kupit/?services=shop_official"
          promocodeValue={promocodeValue}
        />
      </div>
      {popup && <ErrorPopup closePopup={closePopup} />}
    </div>
  );
}
