import React, { useState, useEffect } from 'react';
import s from './Final.module.css';
import Header from '../../components/Header/Header';
import img1 from '../../images/final-1.png';
import img2 from '../../images/final-2.png';
import img3 from '../../images/final-3.png';
import img4 from '../../images/final-4.png';
import img5 from '../../images/final-5.png';
import img6 from '../../images/final-6.png';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import LinkButton from '../../components/LinkButton/LinkButton';
import MiniCard from '../../components/MiniCard/MiniCard';
import ErrorPopup from '../../components/ErrorPopup/ErrorPopup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URL_API } from '../../utils/api';

export default function Final({ spiceId, chainStepHash }) {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    mode: 'free-snap',
    slides: {
      perView: 2.1,
      spacing: 10,
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [loaded, setLoaded] = useState(false);
  const [popup, setPopup] = useState(false);

  const closePopup = () => {
    setPopup(false);
  };
  const openPopup = () => {
    setPopup(true);
  };

  const sendChainStep = () => {
    axios
      .post(`${URL_API}api/web_bot/chain_step`, {
        spice_id: spiceId,
        hash: chainStepHash,
      })
      .then(() => {
        navigate('/rent');
      })
      .catch(() => {
        localStorage.setItem('page', '/rent');
        openPopup();
      });
  };

  useEffect(() => {
    localStorage.setItem('page', '/final');
  }, []);

  return (
    <div className={s.page}>
      <Header transparent={true} />
      <div className={s.container}>
        <h2 className={s.title}>Выберите свой комплимент от бренда</h2>
        <div className={s.cardsContainer}>
          <MiniCard
            linkSrc="/cleaning"
            title="Бесплатная профессиональ-ная чистка"
            img={img1}
            onClicker={() => {
              navigate('/cleaning');
            }}
          />
          <MiniCard
            linkSrc="/rent"
            title="Аренда за 10 руб. от 7 дней"
            img={img2}
            onClicker={sendChainStep}
          />
          <MiniCard
            linkSrc="https://t.me/addstickers/iqos_summer"
            title="Стикерпак в Telegram"
            img={img3}
            onClicker={() => {
              navigate('/stickers');
              // localStorage.setItem('page', '/stickers');
              // window.location.href = 'https://t.me/addstickers/iqos_summer';
            }}
          />
        </div>
        <p className={s.text}>
          А также в Q STORE вас ждет полная коллекция стиков
        </p>
      </div>
      <div className={s.carouselContainer}>
        <div ref={sliderRef} className="keen-slider">
          <div className="keen-slider__slide number-slide1">
            <MiniCard title="Стики Fiit" img={img4} dark={true} />
          </div>

          <div className="keen-slider__slide number-slide2">
            <MiniCard title="Стики HEETS" img={img5} dark={true} />
          </div>
          <div className="keen-slider__slide number-slide3">
            <MiniCard title="Стики HEETS с капсулой" img={img6} dark={true} />
          </div>
        </div>
        {loaded && instanceRef.current && (
          <div className={s.dots}>
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={`${s.dot} ${
                    currentSlide === idx - 1 ? s.active : ''
                  }`}
                ></button>
              );
            })}
          </div>
        )}
      </div>
      <div className={s.container}>
        <LinkButton
          text="Найти Q STORE"
          dark={true}
          src="https://www.iqos.ru/address/gde-kupit/?services=shop_official"
        />
        <p className={s.footnote}>
          Цена в фирменных магазинах Q STORE и в ряде магазинов-партнёров Q
          PARTNER. Цены могут меняться, уточняйте в конкретном выбранном вами
          магазине.
        </p>
        {popup && <ErrorPopup closePopup={closePopup} />}
      </div>
    </div>
  );
}
