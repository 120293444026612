import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import s from './App.module.css';
import FirstQuestion from './pages/FirstQuestion/FirstQuestion';
import GreatChoice from './pages/GreatChoice/GreatChoice';
import Why from './pages/Why/Why';
import Smell from './pages/Smell/Smell';
import BadQuality from './pages/BadQuality/BadQuality';
import Expensive from './pages/Expensive/Expensive';
import Steam from './pages/Steam/Steam';
import DeviceProblems from './pages/DeviceProblems/DeviceProblems';
import OtherDevices from './pages/OtherDevices/OtherDevices';
import Final from './pages/Final/Final';
import Cleaning from './pages/Cleaning/Cleaning';
import Rent from './pages/Rent/Rent';
import Stickers from './pages/Stickers/Stickers';

export default function App() {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const [spiceId, setSpiceId] = useState();
  const [chainStepHash, setChainStepHash] = useState();
  const [promocodeHash, setPromocodeHash] = useState();

  useEffect(() => {
    setSpiceId(searchParams.get('spice_id'));
    setChainStepHash(searchParams.get('chain_step_hash'));
    setPromocodeHash(searchParams.get('promocode_hash'));

    if (localStorage.getItem('spice_id') === searchParams.get('spice_id')) {
      if (localStorage.getItem('page')) {
        navigate(localStorage.getItem('page'));
      }
    } else {
      localStorage.setItem('spice_id', searchParams.get('spice_id'));
      localStorage.setItem('page', '/');
    }
  }, []);

  return (
    <div className={s.app}>
      <Routes>
        <Route path="/" element={<FirstQuestion />} />
        <Route path="/great-choice" element={<GreatChoice />} />
        <Route path="/taste" element={<FirstQuestion />} />
        <Route path="/why" element={<Why />} />
        <Route path="/smell" element={<Smell />} />
        <Route path="/bad-quality" element={<BadQuality />} />
        <Route path="/expensive" element={<Expensive />} />
        <Route path="/steam" element={<Steam />} />
        <Route path="/device-problems" element={<DeviceProblems />} />
        <Route path="/other-devices" element={<OtherDevices />} />
        <Route
          path="/cleaning"
          element={<Cleaning spiceId={spiceId} promocodeHash={promocodeHash} />}
        />
        <Route
          path="/rent"
          element={<Rent spiceId={spiceId} chainStepHash={chainStepHash} />}
        />
        <Route path="/stickers" element={<Stickers />} />
        <Route
          path="/final"
          element={<Final spiceId={spiceId} chainStepHash={chainStepHash} />}
        />
      </Routes>
    </div>
  );
}
