import React from 'react';
import s from './ErrorPopup.module.css';
import warning from '../../images/warning.svg';

export default function ErrorPopup({ closePopup }) {
  return (
    <div className={s.overlay}>
      <div className={s.popup}>
        <button className={s.closeButton} onClick={closePopup}></button>
        <img src={warning} alt="" className={s.warningImg} />
        <h3 className={s.title}>Что-то пошло не так...</h3>
        <p className={s.text}>
          Не волнуйтесь, в течение суток выбранное вами предложение появится в
          разделе «Мои предложения» и вы сможете им воспользоваться.
        </p>
        <button className={s.button} onClick={closePopup}>
          Хорошо
        </button>
      </div>
    </div>
  );
}
