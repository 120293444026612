import React, { useState, useEffect } from 'react';
import s from './OtherDevices.module.css';
import Header from '../../components/Header/Header';
import ComplimentButton from '../../components/ComplimentButton/ComplimentButton';
import SmallCard from '../../components/SmallCard/SmallCard';
import img1 from '../../images/other-1.png';
import img2 from '../../images/other-2.png';
import img3 from '../../images/other-3.png';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

export default function OtherDevices() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    mode: 'free-snap',
    slides: {
      perView: 1.3,
      spacing: 10,
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    localStorage.setItem('page', '/other-devices');
  }, []);

  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <h2 className={s.title}>
          Сейчас мы расскажем вам, какие преимущества есть у IQOS и lil SOLID
        </h2>

        <div ref={sliderRef} className="keen-slider">
          <div className="keen-slider__slide number-slide1">
            <SmallCard
              title="Собрали важные преимущества использования IQOS и lil SOLID по сравнению с курением сигарет"
              img={img1}
              linkText="Узнать о IQOS"
              linkSrc="https://www.iqos.ru/about-iqos/glavnoe/"
              secondLinkText="Узнать о lil SOLID"
              secondLinkSrc="https://www.iqos.ru/lilsolid-benefits/?utm_source=ghips&utm_medium=qr-code&utm_campaign=rent10"
            />
          </div>
          <div className="keen-slider__slide number-slide2">
            <SmallCard
              title="Мы тщательно следим за качеством табачных стиков на всех этапах производства – от табачного листа до стика"
              img={img2}
              linkText="Узнать больше"
              linkSrc="https://cloud.iqos.ru/catmot/?page=test-tobacco&thid=PGRpcmVjdENybVRpY2tldCB0eXBlPSJFbWFpbEF1dGhlbnRpY2F0aW9uVGlja2V0IiBjdXN0b21lcklkPSI2MzgwNjIiIHBhc3N3b3JkPSIiIHRlbXBQYXNzd29yZD0iIiBlbWFpbD0iMDJFN0YyOEE5NzYwQUM0RDY4MDJGQjFEOEU4RUE0ODM1M0EwNEJDRSIgc2lnbmF0dXJlPSJBNDdDNTUwOTQ2ODEyQ0VGMzhBMDRDM0I0NDdBMUQ1NERBNkFFNUJCQjVFNDJGMDA2MUYzRjhGQjhBNjgxRkZEMjc5NUUwNkE0QjM4RDNFODgyNTNDM0ExRjgyMjY1ODRFRjA1MzQ5NjRBMUE1QzQyNUIzRTE0QkEwNzFCOTQxNCIgLz4"
              dark={true}
            />
          </div>
          <div className="keen-slider__slide number-slide3">
            <SmallCard
              title="Приглашаем вас измерить уровень угарного газа в выдыхаемом воздухе"
              img={img3}
              linkText="Найти магазин"
              linkSrc="https://www.iqos.ru/address/gde-kupit/?services=shop_official"
              dark={true}
            />
          </div>
        </div>
        {loaded && instanceRef.current && (
          <div className={s.dots}>
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={`${s.dot} ${currentSlide === idx ? s.active : ''}`}
                ></button>
              );
            })}
          </div>
        )}

        <ComplimentButton />
      </div>
    </div>
  );
}
