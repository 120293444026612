import React, { useEffect } from 'react';
import s from './GreatChoice.module.css';
import Header from '../../components/Header/Header';
import LinkButton from '../../components/LinkButton/LinkButton';
import ComplimentButton from '../../components/ComplimentButton/ComplimentButton';

export default function GreatChoice() {
  useEffect(() => {
    localStorage.setItem('page', '/great-choice');
  }, []);
  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <h2 className={s.title}>Отличный выбор!</h2>
        <div className={s.content}>
          <p className={s.text}>У нас появились стики HEETS с капсулой</p>

          <LinkButton
            text="Гид по вкусам"
            src="https://cloud.iqos.ru/flavor-advisor/"
          />
        </div>
        <ComplimentButton />
      </div>
    </div>
  );
}
