import React from 'react';
import s from './AnswerButton.module.css';
import arrow from '../../images/arrow.svg';
import { Link } from 'react-router-dom';
import { sendData, startData } from '../../utils/api';

export default function AnswerButton({ text, link }) {
  return (
    <Link
      className={s.link}
      to={link}
      onClick={() => {
        if (window.location.pathname === '/') {
          startData(text);
        } else {
          sendData(text);
        }
      }}
    >
      {text} <img src={arrow} alt="arrow" />
    </Link>
  );
}
