import React from 'react';
import s from './ComplimentButton.module.css';
import gift from '../../images/gift.svg';
import { Link } from 'react-router-dom';
import { endData } from '../../utils/api';
import arrow from '../../images/blue-arrow.svg';

export default function ComplimentButton() {
  return (
    <Link className={s.link} to="/final" onClick={endData}>
      <div className={s.circle}>
        <img src={gift} alt="Gift" className={s.img} />
      </div>
      <div className={s.textContainer}>
        <p className={s.text}>Выбрать свой комплимент от бренда</p>
        <p className={`${s.text} ${s.blue}`}>
          Перейти <img src={arrow} alt="arrow" />
        </p>
      </div>
    </Link>
  );
}
